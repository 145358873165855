import type { Departement } from '../models/City'

const departements: Array<Departement> = [
  {
    name: 'Nièvre (58)',
    code: '58',
    label: 'la Nièvre',
    placeHolder: 'la Nièvre (58)'
  } /*,
  {
    name: 'Paris (75)',
    code: '75',
    label: 'Paris',
    placeHolder: 'Paris (75)'
  },
  {
    name: 'Seine-et-Marne (77)',
    code: '77',
    label: 'la Seine-et-Marne',
    placeHolder: 'Seine-et-Marne (77)'
  },
  {
    name: 'Yvelines (78)',
    code: '78',
    label: 'les Yvelines',
    placeHolder: 'Yvelines (78)'
  },
  {
    name: 'Essonne (91)',
    code: '91',
    label: "l'Essonne",
    placeHolder: 'Essonne (91)'
  },
  {
    name: 'Hauts-de-Seine (92)',
    code: '92',
    label: 'les Hauts-de-Seine',
    placeHolder: 'Hauts-de-Seine (92)'
  },
  {
    name: 'Seine-Saint-Denis (93)',
    code: '93',
    label: 'la Seine-Saint-Denis',
    placeHolder: 'Seine-Saint-Denis (93)'
  },
  {
    name: 'Val-de-Marne (94)',
    code: '94',
    label: 'le Val-de-Marne',
    placeHolder: 'Val-de-Marne (94)'
  },
  {
    name: "Val-d'Oise (95)",
    code: '95',
    label: "le Val-d'Oise",
    placeHolder: "Val-d'Oise (95)"
  }
  ,
  {
    name: 'Cher (18)',
    code: '18',
    label : 'le Cher'
  },
  {
    name: 'Allier (03)',
    code: '03',
    label : "l'Allier"
  } */
]

export { departements }
